import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import heroimage from "../assets/images/games_hero@2x.jpg"
import LogoSVG from "../assets/svg/ltb_logotype_condensed.svg"
import { Helmet } from "react-helmet"
import {gsap} from "gsap/all";
import "../scss/styles.scss";

function GamesTeaserPage(props){
    const data = useStaticQuery(
        graphql `
            query {
                site{
                    siteMetadata {
                        title
                        description
                        share_image
                        canonical_url
                    }
                }
            }
        `
    )

    return(
        <div id="game_teaser_page">
            <Helmet>
                <title>{data.site.siteMetadata.title} Games Studio</title>
            </Helmet>
            <div className="background" css={{ backgroundImage: `url(${heroimage})` }}>

            </div>
            <div className="overlay">
                <h1 id="logo_condensed">
                    <img src={LogoSVG} alt={data.site.siteMetadata.title}></img>
                </h1>
                <h2 className="games_studio">games studio</h2>

                <p className="coming_soon">full site coming soon</p>

                <footer className="bottom">
                    <p>©2020 - Some rights reversed - <a href="https://www.lionstigersandbears.be">Lions Tigers and Bears</a> - <a href="https://www.lionstigersandbears.be/presskit">Presskit</a></p>
                </footer>
            </div>
        </div>
    )
}

export default class PageGames extends React.Component{
    componentDidMount(){
        gsap.from("#logo_condensed", {
            opacity: 0,
            duration: 1,
            scale: .2,
            delay: 0
        })

        gsap.from(".games_studio", {
            opacity: 0,
            y: 20,
            duration: 1,
            delay: .5
        })

        gsap.from(".coming_soon", {
            opacity: 0,
            y: 20,
            duration: 1,
            delay: 1
        })

        gsap.from(".bottom", {
            opacity: 0,
            y: 50,
            duration: .5,
            delay: 1.5
        })

        gsap.from(".background", {
            opacity: 0,
            duration: 2,
            delay: 0
        })
    }

    render(){
        return(
            <GamesTeaserPage></GamesTeaserPage>
        )
    }
}